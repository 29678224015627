import React, { useState, useEffect } from 'react';
import GoogleMapReact from "google-map-react";
import MyMarker from "./MyMarker";

const distanceToMouse = (pt, mp) => {
    if (pt && mp) {
        return Math.sqrt(
            (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
        );
    }
};

export default function Carte({ points, selectedDepartment }) {
    const [selectedID, setSelectedID] = useState('');
    const [zoom, setZoom] = useState(6);
    const [center, setCenter] = useState({ lat: 46.603354, lng: 1.888334 });

    const handleClick = (item) => {
        setSelectedID(item.id);
        setCenter({ lat: item.lat, lng: item.lng })
    };

    useEffect(() => {
        if (selectedDepartment) {
            const departementCenter = selectedDepartment.split(',');
            const lat = departementCenter[1].split(':');
            const lng = departementCenter[2].split(':');
            setCenter({ lat: Number(lat[1]), lng: Number(lng[1]) });
            if (departementCenter[0] === '0 - Tous')
                setZoom(6);
            else
                setZoom(9);
        }
    }, [selectedDepartment]);

    const APIKEY = 'AIzaSyA22Rs8C539ogiCOsyTutO3MAMYI6Vv4lE';

    return (
        <div className="App">
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: APIKEY,
                    language: "fr",
                    region: "Fr"
                }}
                center={center}
                zoom={zoom}
                distanceToMouse={distanceToMouse}
               // onClick={handleClickOnMap}
            >
                {points.map((item) => {
                    let departement;
                    if (selectedDepartment)
                        departement = selectedDepartment.split(',')[0].substring(0, 2);
                    if (departement !== '2A' && departement !== '2B' && Number(departement < 10)) {
                        departement = '0' + departement;
                        departement = departement.substring(0, 2);
                    }
                    if (departement === null || departement === undefined || departement === '' || selectedDepartment.split(',')[0] === '0 - Tous' || item.departement === departement) {
                        return <MyMarker
                           key={item.id}
                            id={item.id}
                            lat={item.lat}
                            lng={item.lng}
                            title={item.title}
                            item1={item["chantiers en cours"]}
                            item2={item["chantiers déjà valorisés"]}
                            item3={item["Montant total de prime versé"]}
                            selectedID={selectedID}
                            handleClick={() => handleClick(item)}
                            handleClose={() => setSelectedID('')}
                        />
                    }
                })}
            </GoogleMapReact>
        </div>
    );
}