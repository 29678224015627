import React, { Component } from "react";
import { FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./MyMarker.css";

class MyMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenItem1: false,
      isOpenItem2: false,
      chantierData: null,
    };
  }
  handleScroll = (e) => {
    e.stopPropagation();
  };
  
  componentDidUpdate(prevProps, prevState) {
    const { isOpenItem1, isOpenItem2 } = this.state;
    const { title, selectedID, id } = this.props;

    if (
      (prevState.isOpenItem1 !== isOpenItem1 ||
        prevState.isOpenItem2 !== isOpenItem2) &&
      (isOpenItem1 || isOpenItem2) &&
      selectedID === id
    ) {
      fetch(
        `https://apicartocomptepartenaire.geoplc.fr/getChantier?Name='${title}'&enCours=${isOpenItem1}`
      )
        .then((res) => res.json())
        .then((data) => this.setState({ chantierData: data }))
        .catch((err) => console.error(err));
    } else if (
      (prevState.isOpenItem1 !== isOpenItem1 ||
        prevState.isOpenItem2 !== isOpenItem2) &&
      selectedID !== id
    ) {
      this.setState({ chantierData: null });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.id === nextProps.selectedID ||
      nextProps.id === this.props.selectedID ||
      nextState !== this.state
    );
  }
  handleClose = (event) => {
    event.stopPropagation();
    this.props.handleClose();
  };
  render() {
    const { id, title, item1, item2, item3, selectedID, handleClick } =
      this.props;
    const { isOpenItem1, isOpenItem2, chantierData } = this.state;

    return (
      <div
        className={`${selectedID === id ? "circle hover" : "circle"}`}
        onClick={handleClick}
      >
        <div className={selectedID === id ? "tooltip scrollableContent" : "tooltip-hide"} onWheel={this.handleScroll} >
          <div className="titleContainer">
            <h1>{title}</h1>
            <FaTimes className="faTimes" onClick={this.handleClose} />
          </div>
          <table>
            <tr>
              <td>
                Chantiers en cours
                {isOpenItem1 ? (
                  <FaChevronUp
                    onClick={() =>
                      this.setState({ isOpenItem1: false, isOpenItem2: false })
                    }
                  />
                ) : (
                  <FaChevronDown
                    onClick={() =>
                      this.setState({ isOpenItem1: true, isOpenItem2: false })
                    }
                  />
                )}
              </td>
              <td>{item1}</td>
            </tr>
            {isOpenItem1 && chantierData && (
          <table>
            <thead>
              <tr>
              <th className={"wideColumn"}>Nom du site</th>
              <th className={"wideColumn"}>Statut Cotation</th>
              <th className={"wideColumn"}>Nom du BF</th>
              <th className={"wideColumn"}>Montant prime BF</th>
              </tr>
            </thead>
            <tbody>
              {chantierData.Data[0].enCours.map((chantier, index) => (
                <tr key={`enCours-${index}`}>
                  <td className={"wideColumn"}>{chantier.Cotation_Chantier__r.CHA_Nom_du_site__c}</td>
                  <td className={"wideColumn"}>{chantier.Cotation_Chantier__r.Statut_cotation__c}</td>
                  <td className={"wideColumn"}>{chantier.Cotation_Chantier__r.BEN_PP_NON_Inscrite_Nom__c}</td>
                  <td className={"wideColumn"}>{chantier.Cotation_Chantier__r.Montant_Prime_Ben__c.toLocaleString('fr-FR',{style:'currency',currency:'EUR',minimumFractionDigits:2,})}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
            <tr>
              <td>
                Chantiers déjà valorisés
                {isOpenItem2 ? (
                  <FaChevronUp
                    onClick={() =>
                      this.setState({ isOpenItem2: false, isOpenItem1: false })
                    }
                  />
                ) : (
                  <FaChevronDown
                    onClick={() =>
                      this.setState({ isOpenItem2: true, isOpenItem1: false })
                    }
                  />
                )}
              </td>
              <td>{item2}</td>
            </tr>
            {isOpenItem2 && chantierData && (
          <table>
            <thead>
              <tr>
              <th className={"wideColumn"}>Nom du site</th>
              <th className={"wideColumn"}>Statut Cotation</th>
              <th className={"wideColumn"}>Nom du BF</th>
              <th className={"wideColumn"}>Montant prime BF</th>
              </tr>
            </thead>
            <tbody>
              {chantierData.Data[0].valider.map((chantier, index) => (
                <tr key={`valider-${index}`}>
                  <td className={"wideColumn"}>{chantier.Cotation_Chantier__r.CHA_Nom_du_site__c}</td>
                  <td className={"wideColumn"}>{chantier.Cotation_Chantier__r.Statut_cotation__c}</td>
                  <td className={"wideColumn"}>{chantier.Cotation_Chantier__r.BEN_PP_NON_Inscrite_Nom__c}</td>
                  <td className={"wideColumn"}>{chantier.Cotation_Chantier__r.Montant_Prime_Ben__c.toLocaleString('fr-FR',{style:'currency',currency:'EUR',minimumFractionDigits:2,})}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
            <tr>
              <td>Montant total de prime versé</td>
              <td>
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(item3)}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default MyMarker;
