import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import loader from './loader.svg';
import './App.css';
import Carte from './Carte';

function App() {
  const [data, setData] = useState(null);
  const [departement, setDepartement] = useState('');
  const [departements, setDepartements] = useState([]);

  useEffect(() => {
    const urlDepartements = 'departements.txt';
    fetch(urlDepartements)
      .then((r) => r.text())
      .then((text) => {
        const lines = text.split('\n');
        setDepartements(lines);
      });
  }, []);

  const urlAPI = `https://apicartocomptepartenaire.geoplc.fr/getcoordinates`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(urlAPI);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          const jsonData = await response.json();
          setData(jsonData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSelect = (e) => {
    setDepartement(e.target.value);
  };

  return (
    <div className="App">
      <div className="header">
        <h1>Carto compte partenaire
          {data && (
            <span className="explain">Total: {data["Data"].length} résultats</span>
          )}
        </h1>
        <img src={logo} width="80" />
        <select onChange={handleSelect} value={departement}>
          <option value="">Sélectionnez un département</option>
          {departements.map(dep => <option key={dep} value={dep}>{dep.split(',')[0]}</option>)}
        </select>
      </div>
      {data ? (
        <div>
          <Carte selectedDepartment={departement} points={data['Data']}></Carte>
        </div>
      ) : (
        <div className="loader">
          <img src={loader} width="100" />
          <h2>Loading...</h2>
        </div>
      )}
    </div>
  );
}

export default App;
